<!----------------------- for any edit tell me first : nazih, thanks ------>
<template>
  <section id="users" class="flex flex-col gap-y-20 py-10 h-full">
    <!--- Header -->
    <header class="flex flex-col lg:flex-row justify-between items-end px-2">
      <div class="flex flex-col gap-y-1">
        <h1
          class="
            text-2xl
            font-semibold
            text-gray-700
            flex-1 flex
            items-center
            gap-x-2
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="#111"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17 21v-2c0-1.06-.421-2.078-1.172-2.828C15.078 15.422 14.061 15 13 15H5c-1.06 0-2.078.421-2.828 1.172C1.422 16.922 1 17.939 1 19v2M9 11c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zM23 21v-2c0-.886-.296-1.747-.839-2.448-.543-.7-1.303-1.2-2.161-1.422M16 3.13c.86.22 1.623.72 2.168 1.422.544.702.84 1.565.84 2.453 0 .888-.296 1.751-.84 2.453-.545.701-1.308 1.202-2.168 1.422"
            />
          </svg>
          Utilisateurs
        </h1>
        <div class="font-EffraR text-base" style="color: #545960">
          Les utilisateurs ont accès aux fonctionnalités que vous leurs
          attribuer, mais cela n'inclut pas les données sensibles des patients.
          Par exemple: si vous donnez accès à un utilisateur à la patientèle, il
          aura uniquement le droit de consulter les informations générales sans
          pouvoir accéder au carnet de santé, les consultations, la note
          confidentielle et les documents partagés.
        </div>
      </div>

      <div class="flex flex-row items-center gap-x-2">
        <button
          v-if="!noSubscribed"
          class="
            text-base
            font-EffraR
            bg-dokBlue-ultra
            py-3
            px-5
            text-white
            border-0
            rounded
            cursor-pointer
            truncate
          "
          @click="
            $performTracking({
              data: {
                id: $auth.getUserId(),
                route: 'USERS',
                action: 'NEW',
                data: {},
              },
            });
            modal = true;
          "
        >
          <i class="fas fa-plus-circle mr-2"></i> Créer un nouvel utilisateur
        </button>
      </div>
    </header>

    <template v-if="!loder">
      <!-- List -->
      <section id="listUsers" class="flex flex-col gap-y-2" v-if="users.length">
        <div class="hidden lg:grid gap-x-3 grid-cols-4 items-cente">
          <div></div>
          <div class="font-EffraR text-base" style="color: #545960">
            Fonction
          </div>
          <div class="font-EffraR text-base" style="color: #545960">Roles</div>
          <div class="font-EffraR text-base" style="color: #545960">
            Actions
          </div>
        </div>
        <div
          v-for="(us, index) in users"
          :key="index"
          class="
            bg-white
            border-0 border-b border-solid border-graydok
            py-3
            px-2
            rounded
            grid
            gap-x-3 gap-y-4
            grid-cols-1
            lg:grid-cols-4
            items-center
          "
        >
          <div id="fullName" class="flex flex-row gap-x-3 items-center">
            <img
              :src="$checkAvatar(us.image)"
              class="
                h-16
                w-16
                object-cover
                border border-solid border-grayfoot
                rounded-full
              "
              :alt="`${us.firstName} ${us.lastName}`"
            />
            <span class="font-EffraR text-black text-lg">
              {{ `${us.firstName} ${us.lastName}` }}
            </span>
          </div>
          <div id="job" class="font-EffraR text-black text-lg">
            <div
              class="font-EffraR text-base text-grayDok-sidebar block lg:hidden"
            >
              Fonction :
            </div>
            {{ us.job || "--" }}
          </div>
          <div id="roles" class="font-EffraR text-black text-lg">
            <div
              class="font-EffraR text-base text-grayDok-sidebar block lg:hidden"
            >
              Roles :
            </div>
            {{ us.roles.join(", ") }}
          </div>
          <div id="actions" class="flex items-center gap-x-2">
            <dok-button
              size="md"
              class="flex items-center gap-x-2"
              custom-class="w-full hover:bg-opacity-80"
              @click.native="onEdit(us)"
              aria-label="Modifier"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M20 14.66V20c0 .53-.21 1.04-.586 1.414C19.04 21.79 18.53 22 18 22H4c-.53 0-1.04-.21-1.414-.586C2.21 21.04 2 20.53 2 20V6c0-.53.21-1.04.586-1.414C2.96 4.21 3.47 4 4 4h5.34"
                />
                <path
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M18 2l4 4-10 10H8v-4L18 2z"
                />
              </svg>
              Modifier
            </dok-button>
            <dok-button
              size="md"
              bg="rose"
              class="flex items-center gap-x-2"
              custom-class="w-full hover:bg-opacity-80"
              @click.native="onDelete(us, index)"
              aria-label="Supprimer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 6h18M8 6V4c0-.53.21-1.04.586-1.414C8.96 2.21 9.47 2 10 2h4c.53 0 1.04.21 1.414.586C15.79 2.96 16 3.47 16 4v2m3 0v14c0 .53-.21 1.04-.586 1.414C18.04 21.79 17.53 22 17 22H7c-.53 0-1.04-.21-1.414-.586C5.21 21.04 5 20.53 5 20V6h14zM10 11v6M14 11v6"
                />
              </svg>
              Supprimer
            </dok-button>
          </div>
        </div>
      </section>

      <dok-button
        v-if="users.length && paginate.currentPage < paginate.totalPage"
        size="md"
        @click.native="onGetList(true)"
        aria-label="voir plus"
        >Voir plus</dok-button
      >

      <empty v-if="!users.length">
        <h1 class="text-2xl font-EffraR">La liste est vide.</h1>
        <alerts type="empty1" />
      </empty>
    </template>

    <div class="flex justify-center items-center flex-1" v-if="loder">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        style="margin: auto; display: block; shape-rendering: auto"
        width="200px"
        height="200px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle cx="30" cy="50" fill="#fd5b68" r="20">
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1s"
            keyTimes="0;0.5;1"
            values="30;70;30"
            begin="-0.5s"
          ></animate>
        </circle>
        <circle cx="70" cy="50" fill="#2e6bff" r="20">
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1s"
            keyTimes="0;0.5;1"
            values="30;70;30"
            begin="0s"
          ></animate>
        </circle>
        <circle cx="30" cy="50" fill="#fd5b68" r="20">
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1s"
            keyTimes="0;0.5;1"
            values="30;70;30"
            begin="-0.5s"
          ></animate>
          <animate
            attributeName="fill-opacity"
            values="0;0;1;1"
            calcMode="discrete"
            keyTimes="0;0.499;0.5;1"
            dur="1s"
            repeatCount="indefinite"
          ></animate>
        </circle>
      </svg>
    </div>

    <!-- Modal Create -->
    <modal
      :is-active="modal"
      :exit-modal="
        () => {
          this.modal = false;
        }
      "
      :width="550"
    >
      <formUser :user="user" class="px-3"></formUser>
    </modal>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus";
const modal = () => import("@/views/global-components/dragModal.vue");
const formUser = () => import("./layouts/form.vue");
const alerts = () => import("@/alerts");

export default {
  components: { modal, formUser, alerts },
  data() {
    return {
      user: { roles: [] },
      paginate: { total: 0, totalPage: 0, currentPage: 1 },
      modal: false,
      loder: true,
      noSubscribed: false,
    };
  },
  created() {
    this.noSubscribed = this.$auth.isNotSubscribed();
  },
  mounted() {
    EventBus.$on("Reset_Form_Users", () => {
      this.user = { roles: [] };
    });

    EventBus.$on("close_modal_users", () => {
      this.modal = false;
    });
  },
  computed: {
    users() {
      return this.$store.getters["user/users"];
    },
  },
  async created() {
    await this.onGetList();
  },
  methods: {
    ...mapActions("user", ["GET_LIST", "DELETE_USER"]),
    async onGetList(more = false) {
      this.loder = true;
      if (more) {
        this.paginate.currentPage++;
      }
      const { ok, total, pages } = await this.GET_LIST({
        role:
          this.$auth.getUserRole() === "DOCTOR"
            ? "TEAM-MEMBER"
            : "LABORATORY-MEMBER",
        page: this.paginate.currentPage,
        more,
      });

      if (ok) {
        this.paginate.total = total;
        this.paginate.totalPage = pages;
        this.loder = false;
      }
    },

    async onDelete(user, index) {
      this.$confirm({
        message: `Êtes-vous sûr(e)?`,
        button: {
          no: "Non",
          yes: "Oui",
        },
        callback: async (confirm) => {
          if (confirm) {
            this.$vs.loading();

            const { ok } = await this.DELETE_USER({
              userId: user._id,
            });

            if (ok) {
              this.users.splice(index, 1);
            }

            EventBus.$emit("flashDok", {
              type: ok ? "success" : "errorr",
              message: ok
                ? "Suppression effectuée avec succès"
                : "Erreur au niveau du serveur, merci de réessayer !",
            });

            this.$vs.loading.close();
            this.$performTracking({
              data: {
                id: this.$auth.getUserId(),
                route: "USERS",
                action: "DELETE",
                data: { _id: user._id },
              },
            });
          }
        },
      });
    },

    onEdit(user) {
      this.$performTracking({
        data: {
          id: this.$auth.getUserId(),
          route: "USERS",
          action: "EDIT",
          data: { _id: user._id },
        },
      });
      this.user = user;
      this.$nextTick(() => {
        this.modal = true;
      });
    },
  },
};
</script>
